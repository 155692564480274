import cn from 'classnames';
import styles from './ArrowLinkIcon.module.scss';
import { CommonIconType } from '../types';

type ArrowLinkIconProps = {
  isOpen?: boolean;
  staticArrow?: boolean;
} & CommonIconType;

export function ArrowLinkIcon({
  isOpen,
  width = 24,
  height = 24,
  className,
  style,
  staticArrow = false,
  color = '#979da5',
  onClick,
}: ArrowLinkIconProps) {
  const arrowLinkIconStyles = cn(
    styles.arrowLinkIcon,
    {
      [styles.static]: staticArrow,
      [styles.isOpen]: isOpen,
    },
    className,
  );

  return (
    <div className={arrowLinkIconStyles} onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.svg}
        style={style}
      >
        <path
          d="M12 16L16 12L12 8"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 12L16 12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
