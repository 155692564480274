import { CommonIconType } from './types';

export const BonusIcon = ({
  className,
  onClick,
  style,
  color = '#ffffff',
  width = 12,
  height = 12,
}: CommonIconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
      className={className}
    >
      <rect
        x="1.5"
        y="1.5"
        width="9"
        height="9"
        rx="4.5"
        stroke={color}
        strokeWidth="0.8"
      />
      <path
        d="M5.5716 4.21186C5.76593 3.88895 6.23407 3.88895 6.4284 4.21186L6.71367 4.68588C6.78349 4.80189 6.89736 4.88462 7.02926 4.91517L7.56824 5.04C7.93539 5.12503 8.08006 5.57027 7.83301 5.85487L7.47034 6.27266C7.38158 6.3749 7.33809 6.50877 7.34979 6.64366L7.39763 7.19483C7.43021 7.57029 7.05147 7.84546 6.70446 7.69844L6.19505 7.48263C6.07038 7.42982 5.92962 7.42982 5.80496 7.48263L5.29554 7.69844C4.94853 7.84546 4.56979 7.57029 4.60237 7.19483L4.65021 6.64366C4.66191 6.50877 4.61842 6.3749 4.52966 6.27266L4.16699 5.85487C3.91994 5.57027 4.06461 5.12503 4.43176 5.04L4.97074 4.91517C5.10264 4.88462 5.21651 4.80189 5.28633 4.68588L5.5716 4.21186Z"
        stroke={color}
        strokeWidth="0.8"
      />
    </svg>
  );
};
